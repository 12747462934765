<template>
  <div>
    <v-row>
      <v-col cols="4">
        <v-autocomplete
          v-model="idJobber"
          :items="jobberList"
          item-value="id"
          item-text="nombre"
          label="Jobber"
          dense
          @change="filterJobber()"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <Table
      :header="headerLiquidations"
      :data="liquidations"
      tableName="Listado de Liquidaciones"
    />
  </div>
</template>
<script>
import moment from "moment";
import { GET_LIQUIDATIONS } from "@/core/services/store/liquidation.module";
import Table from "../globals/Table.vue";
import KTUtil from "@/assets/js/components/util";
export default {
  name: "LiquidationList",
  components: {
    Table,
  },
  data() {
    return {
      headerLiquidations: [
        {
          text: "ID",
          value: "idLiquidacion",
        },
        {
          text: "id Jobber",
          value: "idJobber",
        },
        {
          text: "Jobber",
          value: "nombreJobber",
        },
        {
          text: "Estado",
          value: "estadoLiquidacion",
        },
        {
          text: "Tareas",
          value: "cantidadTareas",
        },
        {
          text: "Monto",
          value: "montoTotal",
        },
        {
          text: "N° Factura",
          value: "numeroDocumento",
        },
        {
          text: "Fecha Factura",
          value: "fechaFactura",
        },
        {
          text: "Fecha",
          value: "fechaCreacion",
          render: (item) =>
            moment(item.fechaCreacion, "YYYY-MM-DD HH:mm:ss").format(
              "DD-MM-YYYY HH:mm:ss"
            ),
        },
        {
          text: "",
          value: "actions",
          components: [
            {
              import: async () => await import("./LiquidationDetail.vue"),
              props: (item) => {
                return { liquidationID: item.idLiquidacion };
              },
            },
          ],
        },
      ],
      liquidations: [],
      allLiquidations: [],
      searchLiquidations: "",
      idJobber: null,
    };
  },
  mounted() {
    const oVue = this;
    oVue.getLiquidations();
  },
  methods: {
    getLiquidations() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_LIQUIDATIONS).then(({ data: aLiquidations }) => {
        oVue.liquidations = aLiquidations;
        oVue.allLiquidations = aLiquidations;
        KTUtil.removeLoading();
      });
    },
    filterJobber() {
      const oVue = this;

      let aLiquidations = [];
      const iJobberID = oVue.idJobber;
      if (iJobberID === null) {
        aLiquidations = oVue.allLiquidations;
      } else {
        aLiquidations = oVue.allLiquidations.filter(
          (aLiquidation) => aLiquidation.idJobber === iJobberID
        );
      }
      oVue.liquidations = aLiquidations;
    },
  },
  computed: {
    jobberList() {
      const oVue = this;
      let aJobbersTmp = Object.values(
        oVue.allLiquidations.reduce((acc, el) => {
          (acc[parseInt(el["idJobber"])] =
            acc[parseInt(el["idJobber"])] || []).push(el);
          return acc;
        }, {})
      );

      let aJobbers = aJobbersTmp.map((aJobber) => {
        return {
          id: aJobber[0].idJobber,
          nombre: aJobber[0].nombreJobber,
        };
      });

      aJobbers.unshift({ id: null, nombre: "Todos" });

      return aJobbers;
    },
  },
};
</script>
