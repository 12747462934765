<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="header"
        :items="data"
        :search="search"
        class="table-striped elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{ tableName }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <ExportarTabla
              :filename="tableName"
              :data="data"
              :header="header"
            />
          </v-toolbar>
        </template>
        <template v-for="h in header" v-slot:[`item.${h.value}`]="{ item }">
          <template v-if="typeof h.components !== 'undefined'">
            <template v-for="component in h.components">
              <component
                class="d-inline-flex"
                v-bind:is="component.import"
                v-bind:key="component.name"
                v-bind="component.props(item)"
              ></component>
            </template>
          </template>
          <template v-else-if="typeof h.render !== 'undefined'">
            {{ h.render(item) }}
          </template>
          <template v-else>
            {{ item[h.value] }}
          </template>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
import ExportarTabla from "./ExportarTabla.vue";
export default {
  name: "SimpleTable",
  props: {
    tableName: String,
    header: Array,
    data: Array,
  },
  components: {
    ExportarTabla,
  },
  data() {
    return {
      search: "",
    };
  },
  created() {},
  methods: {},
  computed: {},
  watch: {},
};
</script>
