<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <span class="headline">Listado de Liquidaciones</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <LiquidationList />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import LiquidationList from "../../components/liquidations/LiquidationList.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  name: "list_liquidations",
  components: {
    LiquidationList,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Liquidaciones" },
      { title: "Consulta de liquidaciones", route: "liquidations/list" },
    ]);
  },
};
</script>
